import { useState } from "react";
import { useEffect } from "react/cjs/react.development";

const Numbers = ({ count, index }) => {
  const [numbers, setNumbers] = useState([]);
  useEffect(() => {
    let nums = [];
    for (let i = 0; i < count; i++) {
      nums.push(i + 1);
    }
    setNumbers(nums);
  }, []);

  return (
    <div className="container wrapper flex">
      {numbers.map((number) => (
        <div className="number-container flex flex-col" key={number}>
          <span
            className={`${
              index === number - 1 ? "color-primary" : "color-secondary"
            }`}
          >
            {number}
          </span>
          <div
            className={`number-container__duration
            ${index === number - 1 ? "animate-bg" : "bg-white"}
          `}
          >
            {index === number - 1 && <div></div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Numbers;
