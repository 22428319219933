import { Link } from "react-router-dom";

const Button = ({ text, to, className = "", styles = {}, onClick }) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={`container text-white ${className}`}
      style={{ ...btnStyles, ...styles }}
    >
      {text}
    </Link>
  );
};

const btnStyles = {
  cursor: "pointer",
  border: "none",
  display: "flex",
  justifyContent: "center",
  //   width: "17.125rem",
  width: "70%",
  padding: "1.25rem",
  borderRadius: "1.875rem",
  fontSize: "1.125rem",
  textDecoration: "none",
};

export default Button;
