import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AppHeader from "./components/AppHeader";
import Layout from "./components/Layout";
import Magasins from "./pages/Magasins";
import Questionnaires from "./pages/Questionnaires";
import Restauration from "./pages/Restauration";

const App = () => {
  return (
    <Router>
      <AppHeader />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Magasins />} />
          <Route path="restauration" element={<Restauration />} />
          <Route path="questionnaires" element={<Questionnaires />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
