import { Link } from "react-router-dom";

const AppLink = ({ to = "/", text, Icon, styles, order }) => {
  return (
    <Link to={to} className="container link" style={styles}>
      <p style={{ order }}>{text}</p>
      <img src={Icon} alt="" />
    </Link>
  );
};

export default AppLink;
