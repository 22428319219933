import { NavLink } from "react-router-dom";

import { FaShoppingBag } from "react-icons/fa";
import { IoMdRestaurant, IoMdInformationCircleOutline } from "react-icons/io";

// import ShoppingBag from "../assets/svg/ShoppingBag.svg";
// import Restaurant from "../assets/svg/restaurant.svg";
// import Information from "../assets/svg/Information.svg";

const navigation = [
  { to: "/", icon: FaShoppingBag, text: "Magasins" },
  { to: "/restauration", icon: IoMdRestaurant, text: "Restauration" },
  {
    to: "/questionnaires",
    icon: IoMdInformationCircleOutline,
    text: "Informations",
  },
];

const Navbarlink = ({ to, Icon, text }) => {
  return (
    <NavLink
      className={(navdata) => (navdata.isActive ? "navlink active" : "navlink")}
      to={to}
    >
      <Icon className="navlink__icon" />
      <span className="navlink__text">{text}</span>
    </NavLink>
  );
};

const AppNavigation = () => {
  return (
    <nav className="nav container">
      {navigation.map(({ to, icon, text }) => (
        <Navbarlink key={text} to={to} Icon={icon} text={text} />
      ))}
    </nav>
  );
};

export default AppNavigation;
