import arrow from "../assets/svg/Icon-feather-arrow-left.svg";

import Title from "../components/Title";
import Slider from "../components/Slider";
import Button from "../components/Button";
import Marques from "../components/Marques";
import AppLink from "../components/AppLink";

const Magasins = () => {
  return (
    <>
      <Title text="the place to be" />
      <Slider />
      <Button
        text="Voir toutes les actualités"
        className="bg-primary text-white"
        to="/"
      />
      <Title text="événements tendance" />
      {/* <SliderRotating /> */}
      <Button
        text="Voir tous les événements"
        className="bg-primary text-white"
        to="/"
      />
      {/* <Title text="questionnaires" /> */}
      <Title text="nos marques" />
      <Marques />

      <AppLink
        text="Découvrir tous les magasins"
        Icon={arrow}
        styles={{ justifyContent: "center" }}
      />
    </>
  );
};

export default Magasins;
