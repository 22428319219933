import Question from "./Question";

const QuestionList = ({ questions }) => {
  return (
    <div className="container">
      {questions.map((question, idx) => (
        <Question key={idx} question={question} />
      ))}
    </div>
  );
};

export default QuestionList;
