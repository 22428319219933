const Title = ({ text, className = "", styles = {} }) => {
  return (
    <h1
      style={{ ...titleStyles, ...styles }}
      className={`container text-uppercase color-primary ${className}`}
    >
      {text}
    </h1>
  );
};

const titleStyles = {
  fontSize: "1.875rem",
  textAlign: "center",
};

export default Title;
