import { Outlet } from "react-router-dom";
import AppNavigation from "./AppNavigation";

const Layout = () => (
  <>
    <AppNavigation />
    <Outlet />
  </>
);

export default Layout;
