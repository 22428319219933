import SearchIcon from "../assets/svg/search.svg";
import EllipsisV from "../assets/svg/ellipsisv.svg";
import Qrcode from "../assets/svg/qrcode.svg";

const AppHeader = () => {
  return (
    <header className="header">
      <img src={EllipsisV} className="icon" alt="" />

      <h3 className="logo text-uppercase">Logo</h3>

      <div className="header__right">
        <img src={SearchIcon} className="icon" alt="" />
        <img src={Qrcode} className="icon" alt="" />
      </div>
    </header>
  );
};

export default AppHeader;
