import { useState } from "react";
import { useEffect } from "react/cjs/react.development";
import arrow from "../assets/svg/Icon-feather-arrow-right.svg";

import AppLink from "../components/AppLink";
import Button from "../components/Button";
import Numbers from "../components/Numbers";
import QuestionList from "../components/QuestionList";

const questionsGroup = [
  [
    { question: "Quod iam tamen quos 1", isTrue: false },
    { question: "Quod iam tamen quos aut dumtaxat 2", isTrue: true },
    {
      question: "Veritatis suscipit dicta voluptatem iusto 3",
      isTrue: false,
    },
  ],
  [
    {
      question: "Lorem ipsum dolor sit 4",
      isTrue: false,
    },
    { question: "Tarquinium referre 5", isTrue: false },
    { question: "Amet consectetur adipisicing elit. 6", isTrue: true },
  ],
  [
    {
      question: " voluptas, laboriosam ducimus laudantium 7",
      isTrue: false,
    },
    { question: "Aperiam facilis deleniti? 8", isTrue: false },
    { question: "Quod iam tamen quos aut dumtaxat 9", isTrue: true },
  ],
];

const Questionnaires = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <>
      <AppLink text="Questionnaires" to="" Icon={arrow} order={1} />
      <Numbers count={questionsGroup.length} index={index} />

      {index < questionsGroup.length ? (
        <>
          <QuestionList questions={questionsGroup[index]} />
          <Button
            to=""
            onClick={() => setIndex(index + 1)}
            styles={{ backgroundColor: "#00B620" }}
            className="text-uppercase"
            text="Suivant"
          />
        </>
      ) : (
        <p className="container">Time is out</p>
      )}

      {/* <div className="container">
        <button 
            className=""
        onClick={() => setIndex(index + 1)}>Suivant</button>
      </div> */}
    </>
  );
};

export default Questionnaires;
