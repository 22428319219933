import { useState } from "react";

import Rectangle from "../assets/svg/Rectangle.svg";
import check from "../assets/svg/feather-check.svg";

const Question = ({ question }) => {
  const [isTrue, setIsTrue] = useState(false);

  const onClick = () => {
    setIsTrue(question.isTrue);
  };

  return (
    <div className="question-container">
      <img
        className="pointer"
        onClick={onClick}
        src={isTrue ? check : Rectangle}
        alt=""
      />
      <span>{question.question}</span>
    </div>
  );
};

export default Question;
