import { useEffect, useState } from "react";

const images = [
  {
    src: require("../assets/imgs/marque/GO_Sport_logo.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Image 24.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Image 25.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Image 27.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Logo-beauty-succes.jpg"),
  },
  { src: require("../assets/imgs/marque/swatch-logo.jpg") },
  // from here

  { src: require("../assets/imgs/marque/swatch-logo.jpg") },
  {
    src: require("../assets/imgs/marque/Image 27.jpg"),
  },
  {
    src: require("../assets/imgs/marque/GO_Sport_logo.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Image 25.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Image 24.jpg"),
  },
  {
    src: require("../assets/imgs/marque/Logo-beauty-succes.jpg"),
  },
];

const Marques = ({ displayLines = true }) => {
  const [index, setIndex] = useState(0);
  const [state, setState] = useState([]);
  const [countes, setCountes] = useState();
  const [lines, setLines] = useState([]);

  useEffect(() => {
    setCountes(Math.ceil(images.length / 6));
  }, []);

  useEffect(() => {
    let numbers = [];
    for (let i = 0; i < countes; i++) {
      numbers.push(i);
    }
    setLines(numbers);
  }, [countes]);

  useEffect(() => {
    const res = images.slice(index * 6, (index + 1) * 6);
    if (res.length > 0) setState(res);
    else setIndex(0);
  }, [index]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 4000);

    return () => clearTimeout(timer);
  }, [index]);
  const classNameGenerator = (i) => {
    if (i < index) return "bg-white";
    if (i === index) return "animate-bg";
  };

  return (
    <div className="container">
      <div className="section">
        <div className="marques">
          {state.map((img, i) => (
            <img key={i} src={img.src} alt="" />
          ))}
        </div>
        {displayLines && (
          <div className="section__duration">
            {lines.map((line) => (
              <div className={classNameGenerator(line)} key={line}>
                {line === index && <div></div>}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Marques;
