import { useEffect, useState } from "react";

const images = [
  {
    src: require("../assets/imgs/slider/MaskGroup6.jpg"),
    title: "Lorem ipsum dolor 3",
    subtitle: "www.exemple3.ma",
  },
  {
    src: require("../assets/imgs/slider/p1.jpg"),
    title: "Lorem ipsum dolor 2",
    subtitle: "www.exemple2.ma",
  },
  {
    src: require("../assets/imgs/slider/MaskGroup13.jpg"),
    title: "Anfaplace mall",
    subtitle: "www.anfaplacemall.ma",
  },
];

const Slider = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 4000);

    return () => clearTimeout(timer);
  }, [index]);

  const classNameGenerator = (i) => {
    if (i < index) return "bg-white";
    if (i === index) return "animate-bg";
  };

  return (
    <div className="container slider-container">
      {images.map((image, i) => (
        <div
          key={image.title}
          style={{ opacity: index === i ? 1 : 0 }}
          className="slider-container__info"
        >
          <img src={image.src} alt={image.title} />
          <div className="slider-container__info-detail">
            <h2 className="slider-container__info-detail__title text-uppercase">
              {image.title}
            </h2>
            <p className="slider-container__info-detail__subtitle">
              {image.subtitle}
            </p>
            <div className="slider-container__info-detail__duration">
              {images.map((image, idx) => (
                <div className={classNameGenerator(idx)} key={image.title}>
                  {idx === index && <div></div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slider;
